import React, { useContext } from "react"

import { AppContext } from "../../components/Context"

const MessageUs = () => {
  const appState = useContext(AppContext)

  if (appState.isMobile)
    if (appState.isIphone)
      window.location.href = "https://bcrw.apple.com/urn:biz:040ab1a9-29a8-4dc5-b791-3616dd7bfdfa"
    else
      window.location.href = "sms:+13109974390"

  return(
    <>
    </>
  )
}

export default MessageUs
